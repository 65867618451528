//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    NgZone,
    Renderer2,
    ViewEncapsulation,
} from "@angular/core";
import { S25ItemComponent } from "../s25.item.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-item-space")
@Component({
    selector: "s25-item-space",
    template: `
        @if (init) {
            <s25-item
                [modelBean]="modelBean"
                [includeTypeIcon]="includeTypeIcon"
                [popoverTemplate]="locationTooltip"
                [newTab]="newTab"
            ></s25-item>
        }

        <ng-template #locationTooltip>
            <div class="qtip-content">
                @if (!modelBean.initPopOver) {
                    <div>Loading...</div>
                }
                @if (modelBean.initPopOver && !modelBean.data?.item) {
                    <div class="locationBox popoverroot">Could not load location details</div>
                }
                @if (modelBean.initPopOver && modelBean.data?.item) {
                    <div class="locationBox popoverroot">
                        <div
                            class="boxHeader"
                            (contextmenu)="contextMenu({ itemTypeId: 4, itemId: modelBean.itemId }, $event)"
                        >
                            @if (modelBean.isImage) {
                                <div class="defaultLayoutImageWrapper">
                                    @if (modelBean.data.item.image_id) {
                                        <s25-ng-image
                                            [model]="{
                                                imageId: modelBean.data.item.image_id,
                                                title: modelBean.data.item.conf_name,
                                                bootstrap: { point: 'xs', numCols: 3, maxWidth: 100 },
                                            }"
                                        ></s25-ng-image>
                                    }
                                </div>
                            }
                            <div class="boxHeaderName">
                                {{ modelBean.data.item.room_short }}
                                <div class="boxHeaderFormalName">
                                    {{ modelBean.data.item.room_long }}
                                </div>
                            </div>
                        </div>
                        <div class="boxBody">
                            @if (modelBean.data.item.features) {
                                <div>
                                    <div>
                                        <span class="c-dataLabel">{{ "Features: " }}</span>
                                        <span>{{ modelBean.data.item.features }}</span>
                                    </div>
                                </div>
                            }
                            @if (modelBean.data.item.layouts) {
                                <div>
                                    <div>
                                        <span class="c-dataLabel">{{ "Layouts: " }}</span>
                                        <span>{{ modelBean.data.item.layouts }}</span>
                                    </div>
                                </div>
                            }
                            @if (modelBean.data.item.capacity) {
                                <div>
                                    <div>
                                        <span class="c-dataLabel">{{ "Capacity: " }}</span>
                                        <span>{{ modelBean.data.item.capacity }}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </ng-template>
        <div #contextMenuContainer></div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemSpaceComponent extends S25ItemComponent {
    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        componentFactoryResolver: ComponentFactoryResolver,
        renderer: Renderer2,
    ) {
        super(elementRef, zone, cd, componentFactoryResolver, renderer);
        this.itemTypeId = 4;
    }
}
