//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { jSith } from "../../util/jquery-replacement";
import { TooltipService } from "../../services/tooltip.service";
import { UserprefService } from "../../services/userpref.service";
import { NameService } from "../../services/name.service";
import { StateService } from "../../services/state.service";
import { ItemUtil } from "./item.util";
import { S25ContextMenuComponent } from "../s25-context-menu/context.menu.component";
import { PopoverComponent } from "../s25-popover/popover.component";
import { ContextMenuService } from "../s25-context-menu/context.menu.service";
import { Throttle } from "../../decorators/debounce.decorator";

@TypeManagerDecorator("s25-item")
@Component({
    selector: "s25-item",
    template: `
        @if (init) {
            <s25-popover [modelBean]="modelBean" [disabled]="modelBean.isPrivate">
                <div
                    (click)="itemDetail({ itemTypeId: modelBean.itemTypeId, itemId: modelBean.itemId }, $event)"
                    (mouseup)="
                        $event.button === 1 &&
                            itemDetail({ itemTypeId: modelBean.itemTypeId, itemId: modelBean.itemId }, $event)
                    "
                    (keyup.enter)="itemDetail({ itemTypeId: modelBean.itemTypeId, itemId: modelBean.itemId }, $event)"
                    (contextmenu)="contextMenu({ itemTypeId: modelBean.itemTypeId, itemId: modelBean.itemId }, $event)"
                    class="s25-item-holder"
                    [attr.aria-label]="ariaLabel || modelBean.itemName"
                    [attr.tabindex]="modelBean.isInactive ? null : 0"
                    role="group"
                >
                    @if (!empty) {
                        @if (modelBean.typeIcon?.isActive) {
                            <div
                                class="s25-item-type-icon-holder{{
                                    modelBean.itemType ? '-' + modelBean.itemType : ''
                                }}"
                            >
                                <div class="{{ modelBean.typeIcon.styleClass }} s25-item-type-icon"></div>
                            </div>
                        }
                        <div class="s25-item-name s25-item-link">{{ modelBean.itemName }}</div>
                        @if (modelBean.itemLabel) {
                            <div class="s25-item-label">{{ modelBean.itemLabel }}</div>
                        }
                    }
                    <ng-content></ng-content>
                </div>
            </s25-popover>
        }
        <div #contextMenuContainer></div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ItemComponent implements OnInit, OnDestroy {
    @Input() modelBean: any = undefined;
    @Input() includeTypeIcon: any = undefined;
    @Input() popoverTemplate: TemplateRef<any> = undefined;
    @Input() inactive: boolean = false;
    @Input() empty: boolean;
    @Input() ariaLabel: string;
    @Input() newTab?: boolean;
    @Input() pendingStyleClass: string = "";

    @ViewChild("contextMenuContainer", { read: ViewContainerRef }) contextMenuContainer: ViewContainerRef;
    @ViewChild(PopoverComponent) popoverComponent: PopoverComponent;

    private contextMenuRef: ComponentRef<S25ContextMenuComponent>;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private componentFactoryResover: ComponentFactoryResolver,
        private renderer: Renderer2,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    init = false;
    itemTypeId: number = undefined;
    destroyed = false;
    eventListeners: (() => void)[] = [];
    private itemTypes: any = [
        "event",
        "space",
        "room",
        "location",
        "resource",
        "org",
        "organization",
        "acct",
        "contact",
        "cont",
    ];
    private static TypeMap: any = {
        1: "event",
        4: "space",
        6: "resource",
        2: "organization",
        3: "contact",
    };

    @Throttle(500, false) // No double-clicking!
    itemDetail(item: any, $event: any) {
        let itemTypeId = item.itemTypeId || this.modelBean.itemTypeId;
        let itemId = item.itemId || this.modelBean.itemId;

        // isInactive or isPrivate, do nothing
        // note: isInactive, isPrivate, plainText should still fire parent event because we do not handle the workflow for them
        if (this.modelBean.isInactive || this.modelBean.isPrivate || itemTypeId === 0) {
            //skip inactive and plain text type
            return;
        }

        // stop firing any parent 'click' events when we are clicked
        // http://stackoverflow.com/questions/20300866/angularjs-ng-click-stoppropagation
        // required by Event Details Occurrence panel collapsed reservation row click on s25-item should not expand the row
        $event && $event.stopPropagation && $event.stopPropagation();
        $event && $event.preventDefault && $event.preventDefault();

        this.modelBean.close && this.modelBean.close();

        if (ItemUtil.DetailOverride[itemTypeId]) {
            return ItemUtil.DetailOverride[itemTypeId](this.modelBean);
        }

        let newTab = this.newTab || $event?.ctrlKey || $event?.metaKey || $event?.which === 2 || $event?.button === 1;
        //todo: replace with migrated DropdownPaginatedApi once migrated
        !newTab && window.angBridge.$injector.get("DropdownPaginatedApi").close(window.angBridge.$rootScope);
        StateService.gotoItem(item, newTab);

        if (ItemUtil.ItemComponentsToSearch[itemTypeId]) {
            // task overview
            if (ItemUtil.ItemComponentsToSearch[itemTypeId].task) {
                let task = ItemUtil.ItemComponentsToSearch[itemTypeId].task;
                //wait for AngularJS timeout so that task state is loaded
                //todo: replace with migrated TaskApi once migrated
                //todo: replace $timeout call once StateService is migrated and we can hook into the task state being loaded
                window.angBridge.$timeout(() => {
                    window.angBridge.$injector
                        .get("TaskApi")
                        .taskOverviewSelect(window.angBridge.$rootScope, task.overview || (task.search && itemId));
                });
            }
        }
    }

    contextMenu(item: any, $event: any) {
        $event && $event.stopPropagation && $event.stopPropagation();
        $event && $event.preventDefault && $event.preventDefault();

        let itemTypeId = item.itemTypeId || this.modelBean.itemTypeId;
        let itemId = item.itemId || this.modelBean.itemId;

        // isInactive or isPrivate, do nothing
        if (this.modelBean.isPrivate) {
            return;
        }

        clearTimeout(this.modelBean.openTimeout); //stop popover from showing if we are in contextmenu

        this.popoverComponent?.close();
        ContextMenuService.contextMenuInstance?.destroy();

        const factory = this.componentFactoryResover.resolveComponentFactory(S25ContextMenuComponent);
        this.contextMenuRef = factory.create(this.contextMenuContainer.injector);
        this.renderer.appendChild(
            this.contextMenuContainer.element.nativeElement,
            this.contextMenuRef.location.nativeElement,
        );

        ContextMenuService.contextMenuInstance = this.contextMenuRef;

        this.eventListeners.push(
            this.renderer.listen("document", "click", (event) => {
                if (!this.contextMenuRef.location.nativeElement.contains(event.target)) {
                    this.contextMenuRef?.destroy();
                }
            }),
        );

        this.eventListeners.push(
            this.renderer.listen("document", "keydown", (event) => {
                if (event.key === "Escape") {
                    this.contextMenuRef?.destroy();
                }
            }),
        );

        this.contextMenuRef.instance.data = { itemTypeId, itemId, bean: { startDt: this.modelBean.startDt } };
        this.contextMenuRef.instance.ngOnInit();
    }

    trackByFn(idx: any, item: any) {
        return idx;
    }

    //get popover data on popover shown event
    onShow() {
        return S25Util.all({
            tooltipData:
                [1, 4, 6, 2, 3].indexOf(this.modelBean.itemTypeId) > -1 &&
                TooltipService.getTooltip(this.modelBean.itemTypeId, this.modelBean.itemId, this.modelBean.itemId2),
            timeformat: UserprefService.getS25Timeformat(),
            dateformat: UserprefService.getS25Dateformat(),
        }).then((resp) => {
            this.modelBean.data = resp.tooltipData || {};
            this.modelBean.isImage = this.modelBean.data.item && S25Util.isDefined(this.modelBean.data.item.image_id);
            S25Util.extend(this.modelBean.data, {
                parent: this.modelBean,
                timeFormat: resp.timeformat,
                dateFormat: resp.dateformat,
            });
            this.cd.detectChanges();
        });
    }

    //get itemTypeId, itemId, etc from object-specific data, if needed
    normalizeModelData() {
        if (this.modelBean && S25Util.isUndefined(this.modelBean.itemTypeId)) {
            jSith.forEach(this.itemTypes, (_: number, type: string) => {
                jSith.forEach(["Id", "_id"], (_: number, suffix: string) => {
                    let prop = type + suffix;
                    if (S25Util.isUndefined(this.modelBean.itemTypeId) && S25Util.isDefined(this.modelBean[prop])) {
                        if (type === "event") {
                            this.modelBean.itemTypeId = 1;
                        } else if (type === "resource") {
                            this.modelBean.itemTypeId = 6;
                        } else if (type.indexOf("org") > -1 || type === "acct") {
                            this.modelBean.itemTypeId = 2;
                        } else if (type.indexOf("cont") > -1) {
                            this.modelBean.itemTypeId = 3;
                        } else {
                            this.modelBean.itemTypeId = 4;
                        }

                        this.modelBean.itemId = this.modelBean.itemId || this.modelBean[prop];
                        this.modelBean.itemId2 =
                            this.modelBean.itemId2 || this.modelBean.reservation_id || this.modelBean.reservationId;
                        this.modelBean.itemName =
                            this.modelBean.itemName || this.modelBean[type + "Name"] || this.modelBean[type + "_name"];
                        this.modelBean.itemLabel =
                            this.modelBean.itemLabel ||
                            this.modelBean[type + "Title"] ||
                            this.modelBean[type + "_title"] ||
                            this.modelBean.formalName ||
                            this.modelBean.formal_name;
                    }
                });
            });
        }
    }

    initFn() {
        this.init = true;
        if (!this.destroyed) this.cd.detectChanges();
    }

    ngOnInit() {
        this.zone.run(() => {
            //somehow needed for this.popoverTemplate binding
            if (this.modelBean) {
                this.modelBean.isTooltip = true;
                this.modelBean.isInactive = S25Util.coalesce(this.modelBean.isInactive, S25Util.toBool(this.inactive));
                this.modelBean.itemTypeId = this.modelBean.itemTypeId || this.itemTypeId;
                this.modelBean.isPrivate = this.modelBean.itemName === "(Private)";
                this.normalizeModelData();
                if (this.modelBean.itemTypeId && this.modelBean.itemId) {
                    return S25Util.all({
                        itemName:
                            !this.modelBean.itemName &&
                            NameService.getName(this.modelBean.itemId, this.modelBean.itemTypeId),
                        itemLabel:
                            !this.modelBean.itemName &&
                            NameService.getFormal(this.modelBean.itemId, this.modelBean.itemTypeId),
                    }).then((resp) => {
                        this.modelBean.itemName = this.modelBean.itemName || resp.itemName;
                        this.modelBean.itemLabel = this.modelBean.itemLabel || resp.itemLabel;
                        this.modelBean.itemType =
                            this.modelBean.itemType || S25ItemComponent.TypeMap[this.modelBean.itemTypeId];
                        this.modelBean.typeIcon = this.modelBean.typeIcon || {
                            isActive: S25Util.toBool(this.includeTypeIcon || this.modelBean.includeTypeIcon),
                            styleClass: ItemUtil.IconStyles[this.modelBean.itemTypeId] + this.pendingStyleClass,
                        };
                        this.modelBean.onShow = () => {
                            return this.onShow();
                        };
                        this.modelBean.popoverTemplate = this.popoverTemplate;
                        this.initFn();
                    });
                }
            }
        });
    }

    ngOnDestroy() {
        for (let listener of this.eventListeners) listener();

        this.destroyed = true;
        this.cd.detach();
    }
}
